.App {
	position: relative;
	text-align: center;
	padding: 0 0 50px 0;
}

header,
footer {
	background-color: #444;
	color: white;
	padding: 5px;
	text-align: center;
}

	header h1 {
		font-size: 18pt;
		font-weight: bold;
		margin: 10px 0 5px 0;
	}

	header h2 {
		font-size: 12pt;
		font-weight: normal;
		margin: 5px 0 10px 0;
	}

footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}
