.NetworkGroup {
	display: inline;
}

	.NetworkGroup h2 {
		display: inline-block;
		font-weight: bold;
		font-size: 12pt;
		line-height: 1;
		background-color: #444;
		color: white;
		padding: 5px;
		border-radius: 5px;
		margin: 0;
	}

	.NetworkGroup ul {
		background: #EEE;
		text-align: center;
		padding: 5px 0;
		border: 2px solid #444;
		border-radius: 5px;
		list-style: none;
		margin: 0;
	}

	.NetworkGroup li {
		display: inline;
	}
